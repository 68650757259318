<template>
  <a-upload-dragger
    ref="uploadRef"
    v-model:file-list="fileList"
    class="upload-manual"
    :accept="accept"
    :before-upload="beforeUpload"
    :max-count="1"
    @remove="handleRemove"
    @change="handleChange"
  >
    <p class="ant-upload-drag-icon"><inbox-outlined /></p>
    <p class="ant-upload-text">{{ $t('common_text_008') }}</p>
    <p class="ant-upload-hint">{{ explain }}。{{ $t('common_text_009') }}{{ accept }}</p>
  </a-upload-dragger>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { _oss } from '@/api/index'
import { notification } from 'ant-design-vue'
import i18n from '@/locale/index'
import axios from 'axios'
export default {
  name: 'UploadAuto',
  props: {
    accept: {
      type: String,
      default() {
        return ''
      }
    },
    explain: {
      type: String,
      default() {
        return ''
      }
    },
    fileContent: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['update:fileData'],
  setup(props, ctx) {
    const state = reactive({
      ossObj: {},
      uploadUrl: '',
      file: {},
      fileList: []
    })
    const beforeUpload = async (file, fileList) => {
      const fileArea = file.name.split('.')
      if (fileArea[0] && fileArea[0].length > 100) {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: i18n.global.t('common_text_016')
        })
        file.status = 'fail'
        ctx.emit('update:fileContent', '')
        return false
      }
      if (fileArea[1] && props.accept.indexOf(fileArea[fileArea.length - 1].toLowerCase()) === -1) {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: i18n.global.t('common_text_009') + props.accept
        })
        file.status = 'fail'
        ctx.emit('update:fileContent', '')
        return false
      }
      const res = await _oss.getOssUploadToken({ type: 'UPLOAD' })
      if (res.data.succ) {
        const { data } = res.data
        const fileName = file.name.replace(/[%,，]/g, '')
        state.uploadUrl = data.host
        state.ossObj = {
          key: data.path + fileName,
          policy: data.policy,
          signature: data.signature,
          OSSAccessKeyId: res.data.data.accessId,
          success_action_status: '200'
        }
        ctx.emit('update:fileContent', state.ossObj.key)
      }
      return false
    }
    const handleRemove = () => {
      ctx.emit('update:fileContent', '')
    }
    const handleChange = (info) => {
      state.fileList = info.fileList.filter(item => item.status !== 'fail')
      state.fileList = state.fileList.map(item => {
        return Object.assign({}, item, {
          name: item.name.replace(/[%,，]/g, '')
        })
      })
    }
    const customRequest = () => {
      const file = state.fileList[0]
      if (!file) {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: i18n.global.t('common_text_014')
        })
      }
      const { originFileObj } = file
      state.ossObj.file = originFileObj
      const data = state.ossObj
      axios.post(state.uploadUrl, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(res => {
        ctx.emit('success')
      }).catch(e => console.log(e))
    }
    return {
      customRequest,
      handleChange,
      beforeUpload,
      handleRemove,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.upload-manual {
  .ant-upload-text {
    font-size: 14px !important;
  }
  .ant-upload-hint {
    font-size: 12px !important;
  }
}
</style>

