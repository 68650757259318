<template>
  <div id="eCommerce">
    <a-form
      name="materialForm"
      class="ant-round-form material-fill"
      label-align="left"
      :label-col="{span: 7}"
      :model="materialForm"
      @finish="materialSubmit"
    >
      <div class="material-down flex-align" @click="materialDown">
        <vertical-align-bottom-outlined class="ic-down" />
        <span>{{ $t('pages_receive_083') }}</span>
      </div>
      <a-form-item name="path">
        <upload-manual
          ref="uploadRef"
          v-model:file-content="materialForm.path"
          accept=".xls,.xlsx"
          :explain="$t('pages_receive_082')"
          @success="uploadSuccess"
        />
      </a-form-item>
      <a-form-item class="material-edit">
        <a-button
          :disabled="!materialForm.path"
          :loading="loading"
          html-type="submit"
          type="primary"
          shape="round"
          class="material-btn"
        >{{ $t('common_text_002') }}</a-button>
        <a-button
          shape="round"
          style="margin-left: 18px"
          class="material-btn"
          type="primary"
          ghost
          @click="$router.back(-1)"
        >{{ $t('common_text_003') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import UploadManual from '@/components/upload-manual'
import { exportMaterial } from '@/util'
import { _receive } from '@/api'
import { notification, Modal } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'Ecommerce',
  components: {
    'upload-manual': UploadManual
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const uploadRef = ref(null)

    const state = reactive({
      loading: false,
      applyNo: route.query.applyNo,
      materialForm: {
        path: '',
        bizType: '121011',
        transType: route.query.transType
      }
    })

    const materialDown = () => {
      const { bizType } = state.materialForm
      exportMaterial(bizType)
    }

    const materialSubmit = () => {
      state.loading = true
      uploadRef.value.customRequest()
    }

    const uploadSuccess = async () => {
      if (state.applyNo) state.materialForm.applyNo = state.applyNo
      const res = await _receive.uploadOrderXls(state.materialForm)
      state.loading = false
      const { resCode, resMsg, data } = res.data
      if (res.data.succ) {
        notification.success({
          message: i18n.global.t('common_text_018'),
          description: i18n.global.t('pages_receive_084')
        })
        router.back(-1)
      } else if (resCode === '020015') {
        Modal.error({
          title: i18n.global.t('common_text_015'),
          content: resMsg,
          okText: i18n.global.t('common_text_019'),
          closable: true,
          onOk() {
            window.open(data.failFileName)
          }
        })
      } else if (['020122', '040019'].includes(res.data.resCode)) {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: resMsg
        })
      } else {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: i18n.global.t('common_text_017')
        })
      }
    }

    const initPage = () => {
      state.applyNo = route.query.applyNo
      state.materialForm.transType = route.query.transType
    }

    watch(
      () => router.currentRoute.value,
      (value) => {
        if (value.name === 'materialEcommerce') initPage()
      },
      { immediate: true }
    )

    return {
      uploadRef,
      materialDown,
      materialSubmit,
      uploadSuccess,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#eCommerce {
  .material-fill {
    .material-down {
      margin-bottom: 24px;
      cursor: pointer;
      .ic-down {
        color: @main-color;
        margin-right: 5px;
      }
    }
    .material-edit {
      text-align: center;
      margin-top: 64px;
      .material-btn {
        min-width: 138px;
      }
    }
  }
}
</style>

